import * as React from 'react';
import { useForm } from 'react-final-form';
import {
  required,
  TextInput,
  ReferenceArrayInput,
  FormDataConsumer,
  maxLength,
  minLength,
  number,
} from 'react-admin';
import Grid from '@material-ui/core/Grid';
import { fieldSize, common, entityType } from '../../../constants';
import { NameAutocompleteArrayInput, OrganisationsList } from '../../common';

export default ({ isEdit }) => {
  const form = useForm();
  return (
    <FormDataConsumer>
      {({ formData /* ...rest */ }) => (
        <Grid container spacing={2}>
          {isEdit ? (
            <Grid item md={12} xs={12}>
              <TextInput disabled label='Id' source='id' />
            </Grid>
          ) : null}
          <Grid item md={8} xs={12}>
            <TextInput source='name' fullWidth validate={[required()]} />
          </Grid>
          {formData.entityType !== entityType.demo && (
            <Grid item xs={12}>
              <Grid item md={4} xs={12}>
                <TextInput
                  source='crewCode'
                  fullWidth
                  validate={[
                    required(),
                    maxLength(fieldSize.crewCodeLength),
                    minLength(fieldSize.crewCodeLength),
                    number(),
                  ]}
                  inputProps={{ maxLength: fieldSize.crewCodeLength }}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <OrganisationsList onChange={(/* element */) => form.change('projectIds', [])} />
              </Grid>
              <Grid item md={12} xs={12}>
                <ReferenceArrayInput
                  label='Projects'
                  source='projectIds'
                  resource='default'
                  perPage={formData.organisationId ? common.selectPerPageItems : 0}
                  filter={{ organisationIds: [formData.organisationId] }}
                  fullWidth
                  reference='project'
                >
                  <NameAutocompleteArrayInput />
                </ReferenceArrayInput>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12}>
            {formData.entityType === entityType.demo &&
              'Crew Code for the Demo Crew should be set in server settings.'}
          </Grid>
        </Grid>
      )}
    </FormDataConsumer>
  );
};
