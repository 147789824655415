import * as React from 'react';
import {
  FormDataConsumer,
  AutocompleteInput,
  ReferenceInput,
  required,
  TextInput,
} from 'react-admin';

import Grid from '@material-ui/core/Grid';
import { useForm } from 'react-final-form';
import { OrganisationsList } from '../../common';
import { entityType } from '../../../constants';

export default ({ isEdit }) => {
  const form = useForm();
  return (
    <FormDataConsumer>
      {({ formData /* ...rest */ }) => (
        <Grid container spacing={2}>
          {isEdit ? (
            <Grid item md={12} xs={12}>
              <TextInput disabled label='Id' source='id' />
            </Grid>
          ) : null}

          <Grid item md={12} xs={12}>
            <TextInput source='name' fullWidth validate={[required()]} />
          </Grid>

          {formData.entityType !== entityType.demo && (
            <Grid item md={12} xs={12}>
              <Grid item md={12} xs={12}>
                <OrganisationsList onChange={(/* element */) => form.change('projectId', null)} />
              </Grid>
              <Grid item md={12} xs={12}>
                <ReferenceInput
                  source='projectId'
                  fullWidth
                  reference='project'
                  label='Project'
                  allowEmpty={false}
                  validate={[required()]}
                  filter={{ organisationIds: [formData.organisationId] }}
                >
                  <AutocompleteInput optionText='name' />
                </ReferenceInput>
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
    </FormDataConsumer>
  );
};
