import * as React from 'react';
import { useState } from 'react';
import { useRecordContext } from 'react-admin';
import { Button, Modal, Typography } from '@material-ui/core';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { dataProvider } from '../../../utils';
import useStyles from './styles';
import { entityType } from '../../../constants';

const OrganisationOtpField = props => {
  const record = useRecordContext(props);

  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isOtpAvailable, setIsOtpAvailable] = useState(record && record.otpExpirationDate);
  const [otp, setOtp] = useState(isOtpAvailable ? 'OTP is active' : 'OTP is not active');

  const styles = useStyles();

  const openOtpModal = e => {
    e.stopPropagation();
    setIsModalOpened(true);
  };

  const createOrganisationOtp = () => {
    dataProvider.createOrganisationOtp(record.id).then(response => {
      setOtp(response.json.otp);
      setIsOtpAvailable(true);
    });
  };

  const revokeOrganisationOtp = () => {
    dataProvider.revokeOrganisationOtp(record.id).then((/* response */) => {
      setOtp('OTP is not active');
      setIsOtpAvailable(false);
    });
  };

  if (record.entityType === entityType.demo) {
    return <b title={'OTP for demo organisation can be set only in server settings'}>DEMO</b>;
  }

  return (
    <>
      <Button
        color={isOtpAvailable ? 'primary' : 'secondary'}
        variant={'contained'}
        onClick={openOtpModal}
      >
        <VpnKeyIcon fontSize={'small'} />
      </Button>

      <Modal
        open={isModalOpened}
        onClose={() => setIsModalOpened(false)}
        onClick={e => e.stopPropagation()}
      >
        <div className={styles.modalContainer}>
          <Typography variant='h6' component='h2'>
            {record.name}
          </Typography>
          <Typography sx={{ mt: 2 }} className={styles.otpFieldStyle}>
            {otp}
          </Typography>
          <Typography className={styles.noteStyle}>
            * Make sure to copy the OTP code now. You won’t be able to see it again!
          </Typography>
          <Typography className={styles.noteStyle}>
            ** OTP code is active during 24 hours.
          </Typography>
          <Button color={'secondary'} variant={'contained'} onClick={() => createOrganisationOtp()}>
            Generate new OTP
          </Button>
          <Button onClick={() => revokeOrganisationOtp()}>Revoke OTP immediately</Button>
        </div>
      </Modal>
    </>
  );
};

export default OrganisationOtpField;
