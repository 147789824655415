import * as React from 'react';
import { required, TextInput, AutocompleteInput, maxLength } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import { fieldSize, deviceStatus } from '../../../constants';
import { OrganisationsList } from '../../common';

export default ({ isEdit }) => (
  <Grid container spacing={2}>
    {isEdit ? (
      <Grid item md={12} xs={12}>
        <TextInput disabled label='Id' source='id' />
      </Grid>
    ) : null}
    <Grid item md={6} xs={12}>
      <TextInput
        source='imei'
        label='IMEI (Entered manually)'
        fullWidth
        validate={[maxLength(fieldSize.imeiLength)]}
      />
    </Grid>
    <Grid item md={6} xs={12}>
      <AutocompleteInput
        source='status'
        label='Device status'
        fullWidth
        validate={[required()]}
        choices={deviceStatus}
      />
    </Grid>
    <Grid item md={12} xs={12}>
      <OrganisationsList allowEmpty />
    </Grid>
    <Grid item md={12} xs={12}>
      <TextInput source='note' label='Note (comment)' fullWidth multiline rows={5} />
    </Grid>
  </Grid>
);
