import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    modalContainer: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      backgroundColor: '#fff',
      boxShadow: 24,
      padding: '12px',
      borderRadius: '10px',
    },
    otpFieldStyle: {
      textAlign: 'center',
      lineHeight: '50px',
      height: '50px',
      backgroundColor: '#eee',
      marginBottom: '20px',
      marginTop: '10px',
      borderRadius: '10px',
    },
    noteStyle: {
      fontSize: '9pt',
      margin: '10px 0',
    },
  }),
);

export default useStyles;
