import * as React from 'react';
import { Checkbox, MenuItem, Select, Slider } from '@material-ui/core';
import { ColorPicker } from '../ColorPicker';
import useStyles from './styles';

export const OptionValue = ({ record, updateParameter }) => {
  const currentValue = record.value;
  const styles = useStyles();

  const setCurrentValue = value => {
    // Value reassign is required to override default behavior.
    // eslint-disable-next-line no-param-reassign
    record.organisationOverridden = `${value}` !== `${record.originalValue}`;
    // eslint-disable-next-line no-param-reassign
    record.value = value;
    updateParameter(record);
  };

  if (record.dataType === 'Bool') {
    return (
      <div className={styles.checkboxContainer}>
        <Checkbox
          checked={currentValue === true || currentValue === 'true'}
          onChange={e => setCurrentValue(e.target.checked)}
          className={styles.checkbox}
        />
      </div>
    );
  }
  if (record.fieldType === 'Dropdown') {
    return (
      <Select
        value={currentValue}
        onChange={e => setCurrentValue(e.target.value)}
        className={styles.dropdown}
      >
        {record.appConfigEnums.map(value => (
          <MenuItem key={value.value} value={value.value}>
            {value.name}
          </MenuItem>
        ))}
      </Select>
    );
  }
  if (record.fieldType === 'ColorPalette') {
    return <ColorPicker initialValue={currentValue} onChange={value => setCurrentValue(value)} />;
  }
  if (record.fieldType === 'ColorAlphaPalette') {
    return (
      <ColorPicker
        initialValue={currentValue}
        onChange={value => setCurrentValue(value)}
        withAlpha
      />
    );
  }
  if (record.fieldType === 'Slider') {
    return (
      <Slider
        className={styles.slider}
        min={record.minValue}
        max={record.maxValue}
        step={record.step}
        value={parseFloat(currentValue)}
        onChange={(e, value) => setCurrentValue(value)}
        valueLabelDisplay='auto'
      />
    );
  }
  return null;
};
