import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      marginTop: '30px',
    },
    appConfigTab: {
      minWidth: '80px',
    },
    appConfigBlock: {
      flex: 1,
    },
    appConfigTabPanel: {
      flex: 1,
    },
    appConfigEntry: {
      display: 'flex',
      height: '40px',
      padding: 10,
      borderRadius: 10,
      alignItems: 'center',
    },
    appConfigEntryTitle: {
      minWidth: '250px',
      lineHeight: '40px',
      overflow: 'hidden',
    },
    resetButton: {
      padding: 0,
      minWidth: '32px',
    },
    saveButton: {
      marginTop: 10,
    },
  }),
);

export default useStyles;
