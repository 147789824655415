export const getApiUrl = () => {
  if (process.env.REACT_APP_ENV === 'local') {
    return process.env.REACT_APP_LOCAL_URL;
  }

  if (process.env.REACT_APP_ENV === 'development') {
    return process.env.REACT_APP_DEVELOPMENT_URL;
  }

  return process.env.REACT_APP_PRODUCTION_URL;
};
