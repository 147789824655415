import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    colorPickerBackground: {
      position: 'fixed',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
    },
    colorPickerBar: {
      color: '#fff',
      border: '1px solid #eee',
      borderRadius: '10px',
      height: '25px',
      display: 'flex',
      cursor: 'pointer',
      alignItems: 'center',
      justifyContent: 'center',
      flex: 1,
    },
    colorPickerBarValue: {
      backgroundColor: '#0007',
      borderRadius: '10px',
      fontSize: '10px',
      padding: '2px 5px',
    },
    colorPickerComponent: {
      position: 'absolute',
    },
  }),
);

export default useStyles;
