import * as React from 'react';
import { required, TextInput } from 'react-admin';
import Grid from '@material-ui/core/Grid';

export default ({ isEdit }) => (
  <Grid container spacing={2}>
    {isEdit ? (
      <Grid item md={12} xs={12}>
        <TextInput disabled label='Id' source='id' />
      </Grid>
    ) : null}
    <Grid item md={8} xs={8}>
      <TextInput source='name' fullWidth validate={[required()]} />
    </Grid>
    <Grid item md={4} xs={4}>
      <TextInput source='organizeId' label='OrganizeId' fullWidth />
    </Grid>
  </Grid>
);
