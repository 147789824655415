import * as React from 'react';
import { required, TextInput, AutocompleteInput } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import { scanType } from '../../../constants';

export default ({ isEdit }) => (
  <Grid container spacing={2}>
    {isEdit ? (
      <Grid item md={12} xs={12}>
        <TextInput disabled label='Id' source='id' />
      </Grid>
    ) : null}
    <Grid item md={6} xs={12}>
      <TextInput source='creatorName' fullWidth validate={[required()]} />
    </Grid>
    <Grid item md={6} xs={12}>
      <AutocompleteInput
        source='scanType'
        label='Scan Type'
        fullWidth
        validate={[required()]}
        choices={scanType}
      />
    </Grid>
  </Grid>
);
