import * as React from 'react';
import { List, Datagrid, TextField, DateField } from 'react-admin';

export default props => (
  <List {...props} sort={{ field: 'id', order: 'DESC' }} exporter={false}>
    <Datagrid rowClick='edit'>
      <TextField source='id' />
      <TextField source='text' />
      <TextField source='type' />
      <TextField source='deviceId' />
      <TextField source='appVersion' />
      <DateField source='errorDate' />
      <DateField source='createDate' />
    </Datagrid>
  </List>
);
