import React from 'react';
import { Login, LoginForm } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  main: { background: 'linear-gradient(45deg, rgba(178,226,238,1) 0%, rgba(0,164,205,1) 100%)' },
};

const CustomLoginForm = withStyles({
  button: { background: 'linear-gradient(90deg, rgba(16,197,248,1) 0%, rgba(0,167,205,1) 100%)' },
})(LoginForm);

const LoginPage = props => <Login loginForm={<CustomLoginForm />} {...props} />;

export default withStyles(styles)(LoginPage);
