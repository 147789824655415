import * as React from 'react';
import { Filter, ReferenceInput, AutocompleteInput } from 'react-admin';

export const ListFilter = props => (
  <Filter {...props}>
    <ReferenceInput source='projectId' fullWidth reference='project' label='Project' allowEmpty>
      <AutocompleteInput optionText='name' />
    </ReferenceInput>
  </Filter>
);
