import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    checkbox: {
      padding: 0,
    },
    checkboxContainer: {
      justifyContent: 'flex-end',
      display: 'flex',
      flex: 1,
    },
    dropdown: {
      width: '100%',
    },
    slider: {
      flex: 1,
      alignSelf: 'center',
    },
  }),
);

export default useStyles;
