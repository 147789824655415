import * as React from 'react';
import { Toolbar, SaveButton } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

export const EditActionToolbar = props => (
  <Toolbar {...props} classes={useStyles()}>
    <SaveButton />
  </Toolbar>
);
