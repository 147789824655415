import * as React from 'react';
import { FormDataConsumer, required, TextInput } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import { useForm } from 'react-final-form';
import { OrganisationsList, CollectionAutocomplete } from '../../common';
import { entityType } from '../../../constants';

export default ({ isEdit }) => {
  const form = useForm();
  return (
    <FormDataConsumer>
      {({ formData /* ...rest */ }) => (
        <Grid container spacing={2}>
          {isEdit ? (
            <Grid item md={12} xs={12}>
              <TextInput disabled label='Id' source='id' />
            </Grid>
          ) : null}
          <Grid item md={8} xs={8}>
            <TextInput source='name' fullWidth validate={[required()]} />
          </Grid>
          <Grid item md={4} xs={4}>
            <TextInput source='organizeId' label='OrganizeId' fullWidth />
          </Grid>
          {formData.entityType !== entityType.demo && (
            <Grid item md={12} xs={12}>
              <Grid item md={12} xs={12}>
                <OrganisationsList onChange={(/* element */) => form.change('crewIds', [])} />
              </Grid>
              <Grid item md={12} xs={12}>
                <CollectionAutocomplete
                  label='Crews'
                  source='crewIds'
                  filter={{ organisationId: 'organisationId' }}
                  isAvailable={data => !!data.organisationId}
                  reference='crew'
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
    </FormDataConsumer>
  );
};
