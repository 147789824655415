import { FormDataConsumer, ReferenceArrayInput } from 'react-admin';
import * as React from 'react';
import { common } from '../../../constants';
import { NameAutocompleteArrayInput } from '../NameAutocompleteArrayInput';

export const CollectionAutocomplete = ({ label, filter = {}, reference, source, isAvailable }) => (
  <FormDataConsumer>
    {({ formData /* ...rest */ }) => {
      const filterData = {};

      // TODO refactor to exclude problems with *theoretically* possible unwanted properties.
      // eslint-disable-next-line guard-for-in
      for (const filterKey in filter) {
        filterData[filterKey] = formData[filter[filterKey]];
      }
      return (
        <ReferenceArrayInput
          label={label}
          source={source}
          resource={'default'}
          perPage={!isAvailable || isAvailable(formData) ? common.selectPerPageItems : 0}
          filter={filterData}
          fullWidth
          reference={reference}
        >
          <NameAutocompleteArrayInput />
        </ReferenceArrayInput>
      );
    }}
  </FormDataConsumer>
);
