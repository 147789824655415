import * as React from 'react';
import { Admin, Resource } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';
import {
  People,
  Apartment,
  AccountTree,
  TabletMac,
  SelectAll,
  FormatListBulleted,
  Settings,
  BarChart,
} from '@material-ui/icons';
import { version } from '../package.json';
import {
  Crew,
  Organisation,
  Project,
  Section,
  Device,
  Form,
  AppConfig,
  Analytics,
} from './components/pages';
import LoginPage from './components/pages/User/LoginPage';
import { dataProvider, authProvider } from './utils';
import { theme } from './theme';

const App = () => {
  const i18nProvider = polyglotI18nProvider(() => englishMessages, 'en', { allowMissing: true });

  return (
    <Admin
      disableTelemetry
      title={`Lidar to go - v${version}`}
      theme={theme}
      loginPage={LoginPage}
      i18nProvider={i18nProvider}
      dataProvider={dataProvider}
      authProvider={authProvider}
    >
      <Resource
        name='organisation'
        list={Organisation.List}
        create={Organisation.Create}
        edit={Organisation.Edit}
        icon={Apartment}
      />
      <Resource name='crew' list={Crew.List} create={Crew.Create} edit={Crew.Edit} icon={People} />
      <Resource name='device' list={Device.List} edit={Device.Edit} icon={TabletMac} />
      <Resource
        name='project'
        list={Project.List}
        create={Project.Create}
        edit={Project.Edit}
        icon={AccountTree}
      />
      <Resource
        name='section'
        list={Section.List}
        create={Section.Create}
        edit={Section.Edit}
        icon={SelectAll}
      />
      <Resource name='form' list={Form.List} edit={Form.Edit} icon={FormatListBulleted} />
      <Resource
        name='statAnalytics'
        options={{ label: 'Analytics' }}
        list={Analytics.List}
        edit={Analytics.Edit}
        icon={BarChart}
      />
      <Resource
        name='AppConfig'
        options={{ label: 'App Settings' }}
        icon={Settings}
        list={AppConfig.List}
      />
    </Admin>
  );
};

export default App;
