import { Typography } from '@material-ui/core';
import * as React from 'react';

function TabPanelComponent(props) {
  const { children, value, index, ...other } = props;

  return (
    <>
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <>
            <Typography component={'div'}>{children}</Typography>
          </>
        )}
      </div>
    </>
  );
}

export const TabPanel = TabPanelComponent;
