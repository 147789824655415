import * as React from 'react';
import { List, Datagrid, TextField, DateField } from 'react-admin';
import { BulkActionButtons } from '../../common';
import { rowColorStyle } from '../../../utils';

export default props => (
  <List
    {...props}
    sort={{ field: 'id', order: 'DESC' }}
    bulkActionButtons={<BulkActionButtons resource='crew' />}
    exporter={false}
  >
    <Datagrid rowClick='edit' rowStyle={rowColorStyle}>
      <TextField source='id' />
      <TextField source='name' />
      <TextField source='organisationName' />
      <TextField source='projectsCount' />
      <DateField source='createDate' />
      <DateField source='updateDate' />
    </Datagrid>
  </List>
);
