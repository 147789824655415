import { getApiUrl } from './index';

export const authProvider = {
  login: ({ username, password }) => {
    const request = new Request(`${getApiUrl()}/token`, {
      method: 'POST',
      body: JSON.stringify({ username, password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });
    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ accessToken }) => {
        localStorage.setItem('token', accessToken);
      });
  },
  logout: () => {
    const request = new Request(`${getApiUrl()}/token/all`, {
      method: 'DELETE',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
    });
    localStorage.removeItem('token');
    const logoutPromise = new Promise(resolve => fetch(request).finally(resolve));

    return logoutPromise;
  },
  checkError: error => {
    const { status } = error;
    if (status === 401 || status === 403) {
      localStorage.removeItem('token');
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: () => (localStorage.getItem('token') ? Promise.resolve() : Promise.reject()),
  getPermissions: () => Promise.resolve(),
};
