import * as React from 'react';
import { List, Datagrid, TextField, DateField } from 'react-admin';
import { BulkActionButtons } from '../../common';

export default props => (
  <List
    {...props}
    sort={{ field: 'id', order: 'DESC' }}
    bulkActionButtons={<BulkActionButtons resource='project' />}
    exporter={false}
  >
    <Datagrid rowClick='edit'>
      <TextField source='id' />
      <TextField source='scanType' />
      <TextField source='creatorName' />
      <TextField source='projectName' />
      <TextField source='sectionName' />
      <TextField source='markCount' />
      <DateField source='formCreationDate' />
    </Datagrid>
  </List>
);
