import { Button } from '@material-ui/core';
import * as React from 'react';
import { useRecordContext } from 'react-admin';

export const ButtonField = props => {
  const { onClick, color = 'secondary', variant = 'contained', children } = props;
  const record = useRecordContext(props);

  return (
    <>
      <Button
        color={color}
        variant={variant}
        onClick={e => {
          e.stopPropagation();
          onClick(record);
        }}
      >
        {children}
      </Button>
    </>
  );
};
