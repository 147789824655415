import * as React from 'react';
import { useEffect, useState } from 'react';
import { SketchPicker } from 'react-color';
import { Button } from 'react-admin';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { Popper, Typography } from '@material-ui/core';
import useStyles from './styles';

export const ColorPicker = ({ initialValue, withAlpha, onChange = () => {} }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = useState(initialValue);

  const styles = useStyles();

  const isSketchPickerOpened = Boolean(anchorEl);
  const id = isSketchPickerOpened ? 'color-picker-popover' : undefined;

  const handleOpen = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const onColorChange = newColor => {
    let hexColor = newColor.hex;

    // Workaround to support hex RGBA colors (https://github.com/casesandberg/react-color/issues/510).
    if (withAlpha) {
      let alpha = Math.round(newColor.rgb.a * 255).toString(16);
      if (alpha.length < 2) alpha = `0${alpha}`;
      hexColor = newColor.hex + alpha;
    }

    setValue(hexColor);
    onChange(hexColor);
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div style={{ flex: 1 }}>
          <Button
            className={styles.colorPickerBar}
            style={{ backgroundColor: value }}
            onClick={handleOpen}
            aria-describedby={id}
            fullWidth
          >
            <Typography className={styles.colorPickerBarValue}>{value}</Typography>
          </Button>
          <Popper id={id} open={isSketchPickerOpened} anchorEl={anchorEl} style={{ zIndex: 1000 }}>
            <SketchPicker
              color={value}
              disableAlpha={!withAlpha}
              onChange={newColor => onColorChange(newColor)}
            />
          </Popper>
        </div>
      </ClickAwayListener>
    </>
  );
};
