import { AutocompleteInput, ReferenceInput } from 'react-admin';
import * as React from 'react';

export const OrganisationsList = ({ onChange, allowEmpty = false }) => (
  <ReferenceInput
    source='organisationId'
    fullWidth
    reference='organisation'
    label='Organisation'
    onChange={e => (onChange ? onChange(e) : false)}
    allowEmpty={allowEmpty}
  >
    <AutocompleteInput optionText='name' />
  </ReferenceInput>
);
