import * as React from 'react';
import { Edit, SimpleForm } from 'react-admin';
import { EditActionToolbar } from '../../common';
import { useFailureNotify } from '../../../hooks';
import Form from './Form';

export default props => {
  const notify = useFailureNotify();

  return (
    <Edit onFailure={notify} {...props} undoable={false}>
      <SimpleForm toolbar={<EditActionToolbar />}>
        <Form isEdit />
      </SimpleForm>
    </Edit>
  );
};
