import * as React from 'react';
import { List, Datagrid, TextField, DateField } from 'react-admin';
import { BulkActionButtons } from '../../common';

export default props => (
  <List
    {...props}
    sort={{ field: 'id', order: 'DESC' }}
    bulkActionButtons={<BulkActionButtons resource='organisation' />}
    exporter={false}
  >
    <Datagrid rowClick='edit'>
      <TextField source='id' />
      <TextField source='imei' />
      <TextField source='note' />
      <TextField source='organisationName' />
      <TextField source='crewName' />
      <TextField source='status' />
      <DateField source='lastOnlineDate' showTime />
      <DateField source='createDate' />
      <DateField source='updateDate' />
    </Datagrid>
  </List>
);
