import * as React from 'react';
import { useCallback } from 'react';
import { Button, useRefresh, useNotify, useUnselectAll, useDataProvider } from 'react-admin';
import DeleteIcon from '@material-ui/icons/Delete';

export const BulkDeleteButton = ({ selectedIds, resource }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const unselectAll = useUnselectAll();

  const deleteElements = useCallback(
    () =>
      dataProvider
        .updateMany(resource, { ids: selectedIds, archive: true })
        .then((/* response */) => {
          refresh();
          unselectAll(resource);
          notify('The records were removed successfully.');
        })
        .catch(error => {
          // failure side effects go here
          notify(`Comment approval error: ${error.message}`, 'warning');
        }),
    [dataProvider, notify, refresh, resource, selectedIds, unselectAll],
  );

  return (
    <Button label='Delete' onClick={deleteElements}>
      <DeleteIcon />
    </Button>
  );
};
