import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    logField: {
      lineHeight: '30px',
      padding: '10px',
      borderRadius: '3px',
      backgroundColor: '#ffffee',
    },
  }),
);

export default useStyles;
