import jsonServerProvider from 'ra-data-json-server';
import { httpClient } from './httpClient';

const jsonProvider = jsonServerProvider('', httpClient);

export const dataProvider = {
  ...jsonProvider,
  createOrganisationOtp: id =>
    httpClient(`/organisation/${id}/otp`, {
      method: 'POST',
    }),
  revokeOrganisationOtp: id =>
    httpClient(`/organisation/${id}/otp`, {
      method: 'DELETE',
    }),
  updateMany: (resource, params) => {
    if (params.archive) {
      return httpClient(`/${resource}/archive`, {
        method: 'POST',
        body: JSON.stringify(params.ids),
      }).then((/* response */) => ({
        data: [],
      }));
    }

    return jsonProvider.updateMany(resource, params);
  },
  getGlobalAppConfigs: () => httpClient('/appConfig/global'),
  getOrganisationAppConfigs: organisationId =>
    httpClient(`/appConfig/organisation/${organisationId}`),
};
