import { fetchUtils } from 'react-admin';
import { getApiUrl } from './urlHelper';

export const httpClient = (url, options = {}) => {
  if (!options.headers) {
    // We should change headers to interact with backend correctly.
    // eslint-disable-next-line no-param-reassign
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = localStorage.getItem('token');
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(`${getApiUrl()}${url}`, options);
};
