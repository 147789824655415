import * as React from 'react';
import { useState, useEffect } from 'react';
import { CardContent, Card, Button, Tooltip, Tabs, Tab } from '@material-ui/core';
import RestoreIcon from '@material-ui/icons/Restore';
import { dataProvider } from '../../../utils';
import { OptionValue, TabPanel } from '../../common';
import useStyles from './styles';

export default props => {
  const { location } = props;
  const [appConfigs, setAppConfigs] = useState([]);
  const [organisationName, setOrganisationName] = useState(null);
  const [organisationId, setOrganisationId] = useState(null);
  const [changedParameters, setChangedParameters] = useState([]);

  const styles = useStyles();

  const updateParameter = p => {
    const currentParameters = changedParameters.filter(c => c.key !== p.key);
    setChangedParameters([...currentParameters, p]);
  };
  useEffect(() => {
    const fetchAppConfigs = async () => {
      // Extracting identifier from url, if present.
      const pathArray = location.pathname.split('/');
      let receivedOrganisationId = null;
      if (pathArray.length >= 3) {
        receivedOrganisationId = parseInt(pathArray[2], 10);
      }

      let appConfig;
      if (receivedOrganisationId) {
        appConfig = await dataProvider.getOrganisationAppConfigs(receivedOrganisationId);
        setOrganisationName(appConfig.json.name);
        setOrganisationId(receivedOrganisationId);
      } else {
        appConfig = await dataProvider.getGlobalAppConfigs();
      }

      setAppConfigs(appConfig.json.data);
    };
    fetchAppConfigs();
  }, [location]);

  const updateAppConfigs = async () => {
    const isConfirmed = window.confirm(
      'Are you sure you want to save new default settings? The settings will be applied to all devices',
    );
    if (!isConfirmed) {
      return;
    }
    let params;
    let resource;
    if (organisationId) {
      const updatedSettings = appConfigs
        .filter(c => `${c.value}` !== `${c.originalValue}`)
        .map(c => ({
          appConfigId: c.id,
          value: `${c.value}`,
        }));
      params = {
        id: organisationId,
        data: updatedSettings,
      };
      resource = 'appConfig/organisation';
    } else {
      params = {
        id: '',
        data: changedParameters.map(p => ({
          id: p.id,
          value: `${p.value}`,
        })),
      };
      resource = 'appConfig/global';
    }
    dataProvider.update(resource, params).then(() => {
      setChangedParameters([]);
      alert('The settings were saved successfully');
    });
  };

  const result = appConfigs.reduce((r, a) => {
    const key = a.settingsSection + (a.settingsSubsection ? `: ${a.settingsSubsection}` : '');
    // Reassign is required to reformat result.
    // eslint-disable-next-line no-param-reassign
    r[key] = r[key] || [];
    // eslint-disable-next-line no-param-reassign
    a.sectionKeyName = key;
    r[key].push(a);
    return r;
  }, Object.create(null));

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={styles.container}>
      <h4>
        {organisationName
          ? `Organisation App Settings: ${organisationName}`
          : 'Global App Settings'}
      </h4>
      <Tabs variant='scrollable' value={value} onChange={handleChange}>
        {Object.values(result).map((currentAppConfigs, index) => (
          <Tab
            label={currentAppConfigs[0].sectionKeyName}
            key={currentAppConfigs[0].sectionKeyName}
            aria-controls={`vertical-tabpanel-${index}`}
            id={`vertical-tab-${index}`}
            wrapped
            className={styles.appConfigTab}
          />
        ))}
      </Tabs>
      <Card className={styles.appConfigBlock}>
        <CardContent>
          {Object.values(result).map((currentAppConfigs, index) => (
            <TabPanel
              key={currentAppConfigs[0].sectionKeyName}
              index={index}
              value={value}
              className={styles.appConfigTabPanel}
            >
              {currentAppConfigs.map(appConfig => (
                <div
                  key={appConfig.id}
                  className={styles.appConfigEntry}
                  style={{
                    backgroundColor:
                      appConfig.organisationOverridden && organisationId ? '#BFE6F144' : 'inherit',
                  }}
                >
                  <Tooltip title={appConfig.description}>
                    <div className={styles.appConfigEntryTitle}>
                      {appConfig.title}
                      {appConfig.organisationOverridden && organisationId && (
                        <Button
                          title='Reset value to global'
                          className={styles.resetButton}
                          onClick={() => {
                            const configEntry = appConfigs.find(ac => ac.id === appConfig.id);
                            configEntry.value = configEntry.originalValue;
                            configEntry.organisationOverridden = false;
                            updateParameter(configEntry);
                          }}
                        >
                          <RestoreIcon fontSize={'small'} />
                        </Button>
                      )}
                    </div>
                  </Tooltip>
                  <OptionValue record={appConfig} updateParameter={p => updateParameter(p)} />
                </div>
              ))}
            </TabPanel>
          ))}
        </CardContent>
      </Card>
      <Button
        color={'primary'}
        variant={'contained'}
        disabled={!changedParameters.length && !organisationId}
        onClick={() => updateAppConfigs()}
        label='Save'
        className={styles.saveButton}
      >
        Save
      </Button>
    </div>
  );
};
