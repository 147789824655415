import * as React from 'react';
import { Create, SimpleForm } from 'react-admin';
import Form from './Form';
import { useFailureNotify } from '../../../hooks';

export default props => {
  const failureHandler = useFailureNotify();
  const { basePath } = props;

  return (
    <Create onFailure={failureHandler} {...props}>
      <SimpleForm redirect={basePath}>
        <Form />
      </SimpleForm>
    </Create>
  );
};
